//let _baseUrl = 'https://localhost:9000';
let _baseUrl = 'https://qms-signup-api.imonitor.cloud';
if (process.env.REACT_APP_BUILD_ENV === 'dev') {
    _baseUrl = 'https://qms-dev-signup-api.imonitor.cloud'
} else if (process.env.REACT_APP_BUILD_ENV === 'uat') {
    _baseUrl = 'https://qms-uat-signup-api.imonitor.cloud'
} else if (process.env.REACT_APP_BUILD_ENV === 'prod') {
    _baseUrl = 'https://qms-signup-api.imonitor.cloud'
}

export const GET_HOSTED_CHECKOUT_PAGE = () => `${_baseUrl}/Checkout/getHostedCheckoutPage`;
export const ON_CHECKOUT_SUCCCESS = () => `${_baseUrl}/Checkout/onCheckoutSuccess`;

export const IS_VALID_SUBDOMAIN = (subdomain, sessionId) => `${_baseUrl}/signup/isValidSubdomain/${subdomain}/${sessionId}`;
export const GET_CUSTOMER_SETUP_PROGRESS = (subdomain) => `${_baseUrl}/signup/getCustomerSetupProgress/${subdomain}`;
