import React from 'react';
import styled from 'styled-components';

import { primary, gray400 } from '../../constants/colors';
import StepCircle from './StepCircle';

function ProgressIndicator({ currentStep }) {
    return (
        <CenteredContainer>
            <Container>
                <StepCircle label="User Details" isActive={true} />
                {currentStep > 0 ? <ConnectorActive /> : <Connector />}
                <StepCircle label="Account Setup" isActive={currentStep > 0} />
                {currentStep > 1 ? <ConnectorActive /> : <Connector />}
                <StepCircle label="Billing" isActive={currentStep > 1} />
            </Container>
        </CenteredContainer>
    )
}

const CenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;


const Container = styled.div`
    display: flex;
    flex: 1;
    width: 320px;
    @media (max-width: 768px) {
        width: 280px;
    }
`;

const Connector = styled.div`
    background: ${gray400} 0% 0% no-repeat padding-box;
    height: 3px;
    flex: 1;
    position: relative;
    top: 8px;
    margin-left: -40px;
    margin-right: -40px;
`;

const ConnectorActive = styled(Connector)`
    background: ${primary} 0% 0% no-repeat padding-box;
`;

export default ProgressIndicator;
