import React, { Component } from 'react';
import PropTypes, { string } from 'prop-types';
import styled from 'styled-components'
import axios from 'axios';
import * as DemoSeedConfig from '../../constants/DemoSeedConfig';
import arrow from './arrow.svg';
import { Redirect } from 'react-router-dom';

import { gray200, gray400, gray600, primary, secondary, warning, red , redBackground } from '../../constants/colors';
import { getSubscriptionPlanPricingPoint } from '../../constants/subscriptionPlans';
import { GET_CUSTOMER_SETUP_PROGRESS, GET_HOSTED_CHECKOUT_PAGE, ON_CHECKOUT_SUCCCESS } from '../../constants/apiConfig';
import CheckoutError from '../CheckoutError';
import AccountCreationProgress from '../AccountCreationProgress';
import { hotjar } from 'react-hotjar';
import * as hotjarConfig from '../../constants/hotjarConfig';
const Chargebee = window.Chargebee;

export default class StepPaymentDetails extends Component {
    static propTypes = {
        prop: PropTypes
    }
    
    constructor(props) {
        super(props);

        this.state = {
            checkoutSuccess: false,
            checkoutLoading: true,
            checkoutError: false,
            customerSetupProgress: {
                totalSteps: 0,
                currentStep: 0,
                percentage: 0,
                errorMessage: "",
                steps: ["Turning on the lights", "Turning on the lights", "Turning on the lights", "Polishing surfaces", "Polishing surfaces", "FinalSite almost readyizing"]
            }
        };

        this.onCheckoutSuccess = this.onCheckoutSuccess.bind(this);
        this.getHostedPage = this.getHostedPage.bind(this);
        this.launchChargebeeCheckout = this.launchChargebeeCheckout.bind(this);                      
        hotjar.stateChange(hotjarConfig.stepPaymentDetails);
    }

    onCheckoutSuccess(hostedPageId) {
        const { userDetails, accountDetails, subscriptionPlanId, sessionId } = this.props;

        // console.group("onCheckoutSuccess");
        // console.log(userDetails);
        // console.log(accountDetails);
        // console.groupEnd();

        const chargebeeInstance = Chargebee.getInstance();
        chargebeeInstance.closeAll();

        axios.post(ON_CHECKOUT_SUCCCESS(), {
            hostedPageId,
            subscriptionPlanId,
            userDetails,
            accountDetails,
            sessionId
        });
        var currentTime = new Date();
        const endTime = currentTime.setMinutes(currentTime.getMinutes() + 6);
        this.setState({
            checkoutSuccess: true,
            checkoutLoading: false,
            checkoutError: false
        }, () => {
            //Start polling
            
            const poll = async () => {
                try {
                    if(new Date() > endTime){
                        // stop poll after 6 minutes
                        clearInterval(timer);
                        var customerSetupProgress = Object.assign({}, this.state.customerSetupProgress); 
                        customerSetupProgress.errorMessage = "Setup Progress timeout";
                        this.setState({
                            customerSetupProgress: customerSetupProgress
                        })
                    }

                    const response = await axios.get(GET_CUSTOMER_SETUP_PROGRESS(accountDetails.accountName));

                    if(response.data.currentStep === 6){
                        clearInterval(timer);
                        setTimeout(() => {this.setState({
                            customerSetupProgress: response.data
                        })}, 30000);
                        //console.log(response.data);
                        return;
                    }
                    if(response.data.errorMessage !== "" && response.data.errorMessage !== null && typeof(response.data.errorMessage) !== undefined ){
                        clearInterval(timer);
                    }
                    //console.log(response.data);
                    this.setState({
                        customerSetupProgress: response.data
                    })
                    // console.log(response.data);
                } catch (x) {
                    // Show some error messsage to user?>>>>
                    console.log(x);
                }
            }
            var timer = setInterval(poll, 5000);
        });
    };

    getHostedPage() {
        const { userDetails, accountDetails, subscriptionPlanId } = this.props;

        // console.group("getHostedPage");
        // console.log(userDetails);
        // console.log(accountDetails);
        // console.groupEnd();

        const pricingPoint = getSubscriptionPlanPricingPoint(subscriptionPlanId);

        return axios.post(GET_HOSTED_CHECKOUT_PAGE(),
            {
                customerCompany: accountDetails.companyName,
                customerEmail: userDetails.email,
                customerFirstName: userDetails.firstName,
                customerLastName: userDetails.lastName,
                subscriptionPricingPointId: pricingPoint,
                subdomain: accountDetails.accountName,
                sessionId: this.props.sessionId
            })
            .then((response) => {
                //console.log(response);
                return response.data;
            });
    }

    componentDidMount(prevProps, prevState) {
        
        this.launchChargebeeCheckout();
    }

    launchChargebeeCheckout() {
        const chargebeeInstance = Chargebee.getInstance();
        chargebeeInstance.openCheckout({
            hostedPage: this.getHostedPage,
            loaded: () => {

            },
            error: () => {

            },
            step: (_stepd) => {

            },
            success: this.onCheckoutSuccess,
            close: () => {
                //If Customer close the checkout popup, it will go back to the last step
                this.props.onBackClicked(3);
            }
        });
    }

    render() {
        const { checkoutLoading, checkoutError, checkoutSuccess, customerSetupProgress } = this.state
        const { accountDetails } = this.props;

        return (
            <StyledForm>
                {
                    checkoutLoading &&
                    <LoaderContainer>
                        <div className="lds-ripple">
                            <div></div>
                            <div>
                            </div>
                        </div>
                        <div>
                            Queuing account
                        </div>
                    </LoaderContainer>
                }
                {checkoutError &&
                    <CheckoutError
                        message="An error occurred during checkout." />}

                {
                    checkoutSuccess && customerSetupProgress.currentStep !== 6 && customerSetupProgress.errorMessage === "" &&
                    <div>
                        <LoaderContainer>
                        <AccountCreationText>We're building your account. This will take 5 minutes. Hold tight, please do not refresh the page!</AccountCreationText>
                        </LoaderContainer>
                        <AccountCreationProgress
                            customerSetupProgress={customerSetupProgress}
                        />
                    </div>
                }
                {
                    checkoutSuccess && customerSetupProgress.currentStep !== 6 && customerSetupProgress.errorMessage !== "" &&
                    <div>
                        <LoaderContainer>
                            <ErrorMessageText>Sorry, we got disconnected, but no worries. Your account is being set up. Check your inbox. If you do not receive an email in the next 20 minutes, contact support@imonitor.co.nz.</ErrorMessageText>
                        </LoaderContainer>
                    </div>
                }
                {
                    checkoutSuccess && customerSetupProgress.currentStep === 6 &&
                    <div>
                        <LoaderContainer>
                            <AccountReadyText>Your iMonitor account is ready!</AccountReadyText>
                            <DemoSelectText>What kind of products do you make? </DemoSelectText>
                            <DemoSelectTipText>(Select one to get started)</DemoSelectTipText>
                            <ButtonsContainer>
                                <DemoFoodButton href={`https://${accountDetails.accountName}.imonitor.cloud/dashboard?customerId=${DemoSeedConfig.DemoCustomerId}&regionId=${DemoSeedConfig.DemoRegionId}&siteId=${DemoSeedConfig.DemoFoodManufacturingSiteId}`} target="_blank">
                                    Food
                                </DemoFoodButton>
                                <DemoButton href={`https://${accountDetails.accountName}.imonitor.cloud/dashboard?customerId=${DemoSeedConfig.DemoCustomerId}&regionId=${DemoSeedConfig.DemoRegionId}&siteId=${DemoSeedConfig.DemoPharmaManufacturingSiteId}`} target="_blank">
                                    Pharmaceutical
                                </DemoButton>
                            </ButtonsContainer>
                        </LoaderContainer>

                    </div>
                }
                {
                    !checkoutSuccess &&
                    <ButtonsContainer>
                        <BackButton onClick={() => this.props.onBackClicked(3)}>
                            Back
                        </BackButton>
                    </ButtonsContainer>
                }

            </StyledForm>
        )
    }
}

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const ButtonsContainer = styled.div`
    display: flex;
`;

const LoaderContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const AccountCreationText = styled.div`
    font-size: 14px;
    font-weight: 300;
    color: ${warning};
    margin-bottom: 20px;
    margin-top: 30px;
`;

const AccountReadyText = styled.div`
    font-size: 20px;
    font-weight: 300;
    color: ${secondary};
    margin-bottom: 20px;
    margin-top: 30px;
`;
const DemoSelectText = styled.div`
    font-size: 25px;
    font-weight: 300;
    color: #61C6BC;
    margin-bottom: 5px;
    margin-top: 30px;
`;

const DemoSelectTipText = styled.div`
    font-size: 22px;
    font-weight: 300;
    color: #61C6BC;
    margin-bottom: 20px;
    margin-top: 5px;
`;
const ErrorMessageText = styled.div`
    font-size: 20px;
    font-weight: 300;
    color: ${red};
    background-color:${redBackground};
    text-align:center;
    margin-bottom: 20px;
    margin-top: 30px;
    border-style: solid;
    border-color: ${red};
    border-radius: 3px;
    border-width: thin;
`;

const BackButton = styled.div`
  background: ${gray200} 0% 0% no-repeat padding-box;
  border:none;
  border-radius: 4px;
  opacity: 1;
  width: 99px;
  height: 34px;
  color: ${gray600};
  margin-top: 30px;
  cursor: pointer;
  box-shadow: 0 2px #999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-right: 15px;
  user-select: none;

  &:hover {
    background-color: ${gray400};
  }

  &:active {
    background-color: ${gray400};
    box-shadow: 0 2px #666;
    transform: translateY(2px);
  }
`;

const SubmitButton = styled.button`
  background: ${primary} 0% 0% no-repeat padding-box;
  border:none;
  border-radius: 4px;
  opacity: 1;
  width: 99px;
  height: 34px;
  color: #FFFFFF;
  margin-top: 30px;
  cursor: pointer;
  box-shadow: 0 2px #999;

  &:hover {
    background-color: #3e8e41;
  }

  &:active {
    background-color: #3e8e41;
    box-shadow: 0 2px #666;
    transform: translateY(2px);
  }
`;

const GetStartedButton = styled.a`
    background: ${primary} 0% 0% no-repeat padding-box;
    border:none;
    border-radius: 4px;
    opacity: 1;
    width: 99px;
    height: 34px;
    color: #FFFFFF;
    cursor: pointer;
    box-shadow: 0 2px #999;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    text-decoration: none;

    &:hover {
    background-color: #3e8e41;
    }

    &:active {
    background-color: #3e8e41;
    box-shadow: 0 2px #666;
    transform: translateY(2px);
    }
`;

const DemoButton = styled.a`
    background:  url(${arrow})  #61C6BC 0% 0% no-repeat padding-box;
    background-position: 165px 7px;
    border:none;
    border-radius: 4px;
    opacity: 1;
    width: 199px;
    height: 34px;
    color: #FFFFFF;
    cursor: pointer;
    box-shadow: 0 2px #999;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    text-decoration: none;
    margin:5px;


    &:active {
    box-shadow: 0 2px #666;
    transform: translateY(2px);
    }
`;
const DemoFoodButton = styled.a`
    background:  url(${arrow})  #61C6BC 0% 0% no-repeat padding-box;
    background-position: 90px 7px;
    border:none;
    border-radius: 4px;
    opacity: 1;
    width: 120px;
    height: 34px;
    color: #FFFFFF;
    cursor: pointer;
    box-shadow: 0 2px #999;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    text-decoration: none;
    margin:5px;


    &:active {
    box-shadow: 0 2px #666;
    transform: translateY(2px);
    }
`;
