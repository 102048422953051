import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useSpring, animated } from 'react-spring'

import { cyan, gray200, gray400, secondary } from '../../constants/colors';

const PROGRESS_BAR_WIDTH = 260;

function AccountCreationProgress({ customerSetupProgress }) {
    const [pbWidth, setPbWidth] = useState(3);
    const [message, setMessage] = useState('Starting setup...');

    const progressBarProps = useSpring({
        to: {
            width: pbWidth
        },
        from: {
            width: 0
        }
    });

    useEffect(() => {
        const getMessageForStep = (step) => {
            if (step === 0) {
                return 'Starting account setup...'
            }
            const { steps } = customerSetupProgress;
            return steps[step - 1];
        }

        const getProgressBarWidth = (percentage) => {
            const { currentStep } = customerSetupProgress;
            return currentStep === 0 ? PROGRESS_BAR_WIDTH * 0.1 : percentage * PROGRESS_BAR_WIDTH;
        }

        const width = getProgressBarWidth(customerSetupProgress.percentage);
        setPbWidth(width);

        const message = getMessageForStep(customerSetupProgress.currentStep);
        setMessage(message);

    }, [customerSetupProgress])

    const { currentStep } = customerSetupProgress;
    const pbColor = cyan;
    return (
        <Container>
            <Meter>
                <MeterProgresBar style={progressBarProps} color={pbColor} />
            </Meter>
            <ProvisioningMessage>
                {message}
            </ProvisioningMessage>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;


const Meter = styled.div`
    box-sizing: content-box;
    height: 20px; /* Can be anything */
    position: relative;
    margin: 60px 0 20px 0; /* Just for demo spacing */
    background: ${gray200};
    border-radius: 25px;
    padding: 10px;
    box-shadow: inset 0 -1px 1px ${gray400};
    width: ${PROGRESS_BAR_WIDTH}px;
`

const Move = keyframes`
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 50px 50px;
    }
  }
  `;

export const MeterProgresBar = styled(animated.span)`
    display: block;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: ${({ color }) => color};

    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: linear-gradient(
          -45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent
        );
        z-index: 1;
        background-size: 50px 50px;
        animation: ${Move} 2s linear infinite;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        overflow: hidden;                
    }
`;

const ProvisioningMessage = styled.div`
    font-size: 20px;
    font-weight: 300;
    color: ${secondary};
    margin-bottom: 20px;
`


export default AccountCreationProgress
