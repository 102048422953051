import React from 'react'
import styled from 'styled-components'
import { ErrorMessage } from 'formik';
import { red } from '../../constants/colors';

function TextInput(props) {
    const { name, label } = props;
    return (
        <div>
            <InputContainer>
                <Label>{label}</Label>
                <Input {...props} />
            </InputContainer>
            <ErrorMessageContainer>
                <ErrorMessage name={name} />
            </ErrorMessageContainer>
        </div>
    )
}


export default TextInput;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
`

const Input = styled.input`
  flex: 1;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CED4DA;
  font-size: 16px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`


const ErrorMessageContainer = styled.div`
    font-size: 12px;
    color: ${red};
`;

