import React from 'react';
import styled from 'styled-components';
import { tealStroke, cyan, secondary, warning } from '../../constants/colors';


function CheckoutError({ message }) {
    return (
        <Container>
            <Message>{message}</Message>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: #fff0f4;
    border: 1px solid #c51244; 
`;

const Message = styled.div`
`;

export default CheckoutError
