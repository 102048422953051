import React from 'react';
import styled from 'styled-components'
import { red } from '../../constants/colors';
import { ErrorMessage } from 'formik';

function AccountNameInput(props) {
    const { name, label, value, errors } = props;
    return (
        <div>
            <InputContainer>
                <Label>{label}</Label>
                <Input {...props} />
            </InputContainer>
            {
                errors ?
                    <ErrorMessageContainer>
                        <ErrorMessage name={name} />
                    </ErrorMessageContainer>
                    :
                    <AccountNameContainer>
                        Your custom URL will be
                        <SubdomainText>
                            {value}.imonitor.cloud
                        </SubdomainText>
                    </AccountNameContainer>
            }
        </div >
    )
}


const AccountNameContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
`;

const SubdomainText = styled.span`
    font-weight: bold;
    margin-left: 6px;
    word-wrap:break-word;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
`

const Input = styled.input`
  flex: 1;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CED4DA;
  font-size: 16px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`

const ErrorMessageContainer = styled.div`
    font-size: 12px;
    color: ${red};
`;

export default AccountNameInput
