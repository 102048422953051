import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import step1 from './step1.svg';
import step2 from './step2.svg';
import step3 from './step3.svg';

import { cyan, gray200, gray400, secondary } from '../../constants/colors';

function MarketingPanel({ currentStep }) {
    const RenderStepImage = (currentStep)=>{
        if(currentStep === 0){
            return (
                <Image>
                    <img src={step1} alt="image"></img>
                </Image>
            ) 
        }
        else if(currentStep === 1){
            return (
                <Image>
                    <img src={step2} alt="image"></img>
                </Image>
            )  
        }
        else if(currentStep === 2){
            return (
                <Image>
                    <img src={step3} alt="image"></img>
                </Image>
            ) 
        }
          
    }
    const upperMessages = ["Start digitising your manufacturing floor with iMonitor today.","Get real-time production insights by eliminating paper-based checks and storing all data securely in the cloud.","Trace sensitive ingredients, products and processes in seconds, not days with iMonitor’s traceability feature."];
    const downMessages = ["You are only three easy steps away from starting your free iMonitor trial.","Only two more easy steps until you can start your free iMonitor trial.","You are almost ready to start your free iMonitor trial."];

    
    return (
        <Container>
            {RenderStepImage(currentStep)}
            <UpperMessage>
            {upperMessages[currentStep]}
            </UpperMessage>
            <DownMessage>
            {downMessages[currentStep]}
            </DownMessage>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;


const UpperMessage = styled.div`
    font-size: 21px;
    font-weight: bold;
    color: ${secondary};
    margin-bottom: 20px;
    text-align: center;
`;
const DownMessage = styled.div`
    font-size: 18px;
    font-weight: 300;
    color: ${secondary};
    margin-bottom: 20px;
    text-align: center;
`;
const Image = styled.div`
    height: 180px;
    padding-top:20px;
    padding-bottom:20px;
    display: flex;
    justify-content: center;
    min-width: 100px;
  @media (min-width: 768px) {
    height: 250px;
    padding: 30px;
  }
`;


export default MarketingPanel
