import { isNullOrWhitespace } from "../utils/validators";

export const professional = 'imonitor-qms-professional';
export const standard = 'imonitor-qms-standard';
export const ipp = 'imonitor-qms-ipp';
export const mesTrial = 'MES-Trial';

const subscriptionPricingPoints = {
    [standard]: 'imonitor-qms-standard-USD-Monthly',
    [professional]: 'imonitor-qms-professional-USD-Monthly',
    [ipp]: 'imonitor-qms-ipp-NZD-Monthly',
    [mesTrial]: 'MES-Trial-NZD-Monthly'
}

const validSubscriptionPlans = [professional, standard, ipp, mesTrial];
export const isValidSubscriptionPlan = (subscriptionPlan) => {
    if (isNullOrWhitespace(subscriptionPlan)) {
        return false;
    }

    return validSubscriptionPlans.indexOf(subscriptionPlan) > -1;
}


export const getSubscriptionPlanPricingPoint = (subscriptionPlanId) => {
    return subscriptionPricingPoints[subscriptionPlanId];
}