import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import SignupForm from './components/SignupForm';

function App() {
  const sessionId = `${(new Date()).getTime()}`;
  return (
    <Router>
      <Switch>
        <Route path="/:subscriptionPlanId">
          <SignupForm sessionId={sessionId} />
        </Route>
        <Route path="*">
          <SignupForm sessionId={sessionId} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
