import React from 'react'
import styled from 'styled-components';

import { primary, gray400 } from '../../constants/colors';

function StepCircle({ label, isActive }) {
    return (
        <Container>
            <CircleContainer>
                {isActive ? <CircleActive /> : <Circle />}
            </CircleContainer>
            <Label>{label}</Label>
        </Container>
    )
}

const Container = styled.div`
`;

const CircleContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    margin-bottom: 10px;
`;

const Circle = styled.div`
  background: ${gray400} 0% 0% no-repeat padding-box;
  height: 20px;
  width: 20px;
  border-radius: 50%;
`;

const CircleActive = styled(Circle)`
  background: ${primary} 0% 0% no-repeat padding-box;
`;

const Label = styled.div`
    font-size: 12px;
    text-align: center;
    width: 100px;
`;

export default StepCircle;
