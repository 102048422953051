import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Field, Form } from 'formik';
import zxcvbn from 'zxcvbn';

import TextInput from '../TextInput';
import { isNullOrWhitespace, isValidEmail } from '../../utils/validators';
import { primary } from '../../constants/colors';
import PasswordStrengthIndicator from '../PasswordStrengthIndicator';
import { hotjar } from 'react-hotjar';
import * as hotjarConfig from '../../constants/hotjarConfig';
function StepUserDetails({ onStepComplete, initialState }) {    
    hotjar.stateChange(hotjarConfig.stepUserDetails);
    let score = -1;
    if (!isNullOrWhitespace(initialState.password)) {
        var result = zxcvbn(initialState.password);
        score = result.score;
    }

    const [passwordStrengthScore, setPasswordStrengthScore] = useState(score);

    const validate = (values, _props) => {
        const errors = {};

        Object.keys(initialState).forEach(key => {
            if (isNullOrWhitespace(values[key])) {
                const result = key.replace(/([A-Z])/g, " $1");
                const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                errors[key] = `${finalResult} is required.`
            }
        });
        const flag = new RegExp(/[`~!@#$^&*()=|{}':;',\\[\]<>《》"\/?~！@#￥……&*（）|{}【】‘；：”“'。，、？]/);
        if(values.firstName.length > 100){
            errors.firstName = 'First name is too long';
        }
        if(values.lastName.length > 100){
            errors.lastName = 'Last name is too long';
        }
        if(values.email.length > 100){
            errors.email = 'Email is too long';
        }
        if(values.password.length > 100){
            errors.password = 'Password is too long';
        }
        if (!('email' in errors) && !isValidEmail(values['email'])) {
            errors.email = 'Invalid email address';
        }
        if(flag.test(values.firstName)){
            errors.firstName = 'First name is invalid';
        }
        if(flag.test(values.lastName)){
            errors.lastName = 'Last name is invalid';
        }

        if (!isNullOrWhitespace(values.confirmPassword) && values.password !== values.confirmPassword) {
            errors.confirmPassword = `Passwords don't match`;
        }

        let score = -1;
        if (!isNullOrWhitespace(values.password)) {
            var result = zxcvbn(values.password);
            setPasswordStrengthScore(result.score);
            score = result.score;
        } else {
            setPasswordStrengthScore(score);
        }

        if(score < 4) {
            errors.password = `Password is too weak. Please choose a stronger password.`
        }

        return errors;
    }


    const onSubmit = (values, _actions) => {
        // setTimeout(() => {
        //     alert(JSON.stringify(values, null, 2));
        //     actions.setSubmitting(false);
        // }, 1000);
        onStepComplete(1, values);
    };

    return (
        <Formik
            initialValues={initialState}
            validate={validate}
            onSubmit={onSubmit}>
            {
                (props) => (
                    <StyledForm>
                        <Field name="firstName" type="text" label="First Name" autocomplete="name" as={TextInput} />
                        <Field name="lastName" type="text" label="Last Name" autocomplete="family-name" as={TextInput} />
                        <Field name="email" type="email" label="Email" as={TextInput} autocomplete="email" />

                        <Field name="password" type="password" label="Password" as={TextInput} />
                        <PasswordStrengthIndicator score={passwordStrengthScore} />
                        <Field name="confirmPassword" type="password" label="Confirm Password" as={TextInput} />

                        <SubmitButton type="submit" disabled={props.isSubmitting}>
                            Next
                        </SubmitButton>
                    </StyledForm>
                )
            }
        </Formik>
    )
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const SubmitButton = styled.button`
  background: ${primary} 0% 0% no-repeat padding-box;
  border:none;
  border-radius: 4px;
  opacity: 1;
  width: 99px;
  height: 34px;
  color: #FFFFFF;
  margin-top: 30px;
  cursor: pointer;
  box-shadow: 0 2px #999;
  
  &:hover {
    background-color: #3e8e41;
  }

  &:active {
    background-color: #3e8e41;
    box-shadow: 0 2px #666;
    transform: translateY(2px);
  }
`

export default StepUserDetails
