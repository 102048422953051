import React from 'react'
import styled from 'styled-components'
import { Formik, Field, Form } from 'formik';
import axios from 'axios';

import TextInput from '../TextInput';
import { isNullOrWhitespace } from '../../utils/validators';
import { gray200, gray400, gray600, primary } from '../../constants/colors';
import AccountNameInput from '../AccountNameInput';
import { IS_VALID_SUBDOMAIN } from '../../constants/apiConfig';
import { hotjar } from 'react-hotjar';
import * as hotjarConfig from '../../constants/hotjarConfig';
const invalidChars = new RegExp(/[`~!@#$^&*()=|{}':;',\\[\]<>《》"\/?~！@#￥……&*（）|{}【】‘；：”“'。，、？]/);
function StepAccountDetails({ onStepComplete, onBackClicked, initialState, sessionId }) {

  hotjar.stateChange(hotjarConfig.stepAccountDetails);
  
  const validate = async (values, _props) => {
    const errors = {};

    Object.keys(initialState).forEach(key => {
      if (isNullOrWhitespace(values[key])) {
        const result = key.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        errors[key] = `${finalResult} is required.`
      }
    });
    if (values.companyName.length > 100) {
      errors.companyName = 'Company Name is too long';
    }
    if (values.regionName.length > 100) {
      errors.regionName = 'Region Name is too long';
    }
    if (values.siteName.length > 100) {
      errors.siteName = 'Site Name is too long';
    }
    if (invalidChars.test(values.companyName)) {
      errors.companyName = 'Company name is invalid';
    }
    if (invalidChars.test(values.regionName)) {
      errors.regionName = 'Region name is invalid';
    }
    if (invalidChars.test(values.siteName)) {
      errors.siteName = 'Site name is invalid';
    }
    //errors.accountName = await validateAccountName(values.accountName);
    return errors;
  }

  const validateAccountName = async value => {
    try {
      if (value.length > 25) {
        return 'Account name is too long';
      }

      if (invalidChars.test(value)) {
        return 'Account name is invalid';
      }

      if (/^[A-Za-z0-9].*/.test(value) === false) {
        return 'Account name must start with a letter or a digit';
      }

      if (/.*[A-Za-z0-9]$/.test(value) === false) {
        return 'Account name must end with a letter or a digit';
      }
      
      var result = await axios.get(IS_VALID_SUBDOMAIN(value, sessionId));
      const { valid, message } = result.data;
      if (!valid) {
        return 'The account name is already in use. Please select another one.';
      }

      return undefined;
    } catch (x) {
      return 'Failed to validate account name.';
    }
  };

  const onSubmit = (values, actions) => {
    onStepComplete(2, values);
  };

  //console.log(initialState);

  return (
    <Formik
      initialValues={initialState}
      validate={validate}
      onSubmit={onSubmit}>
      {
        (props) => {
          //console.log(props);
          return (
            <StyledForm>
              <Field name="accountName"
                type="text"
                label="Account Name"
                validate={validateAccountName}
                errors={props.errors.accountName}
                as={AccountNameInput} />
              <Field name="companyName" type="text" label="Company Name" as={TextInput} />
              <Field name="regionName" type="text" label="Region Name" as={TextInput} />
              <Field name="siteName" type="text" label="Site Name" as={TextInput} />
              <ButtonsContainer>
                <BackButton disabled={props.isSubmitting} onClick={() => onBackClicked(2, props.values)}>
                  Back
                </BackButton>
                <SubmitButton type="submit" disabled={props.isSubmitting}>
                  Next
                </SubmitButton>
              </ButtonsContainer>
            </StyledForm>
          )
        }
      }
    </Formik>
  )
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;


const SubmitButton = styled.button`
  background: ${primary} 0% 0% no-repeat padding-box;
  border:none;
  border-radius: 4px;
  opacity: 1;
  width: 99px;
  height: 34px;
  color: #FFFFFF;
  margin-top: 30px;
  cursor: pointer;
  box-shadow: 0 2px #999;
  
  &:hover {
    background-color: #3e8e41;
  }

  &:active {
    background-color: #3e8e41;
    box-shadow: 0 2px #666;
    transform: translateY(2px);
  }
`;
const ButtonsContainer = styled.div`
    display: flex;
`;

const BackButton = styled.div`
  background: ${gray200} 0% 0% no-repeat padding-box;
  border:none;
  border-radius: 4px;
  opacity: 1;
  width: 99px;
  height: 34px;
  color: ${gray600};
  margin-top: 30px;
  cursor: pointer;
  box-shadow: 0 2px #999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-right: 15px;
  user-select: none;

  &:hover {
    background-color: ${gray400};
  }

  &:active {
    background-color: ${gray400};
    box-shadow: 0 2px #666;
    transform: translateY(2px);
  }
`;

const AccountNameContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
`;
const SubdomainText = styled.span`
    font-weight: bold;
    word-wrap:break-word;
`;

export default StepAccountDetails
