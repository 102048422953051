import React from 'react'
import styled, { css } from 'styled-components'
import { gray400 } from '../../constants/colors';

function PasswordStrengthIndicator({ score }) {
    let [color0, color1, color2, color3, color4] = [gray400, gray400, gray400, gray400, gray400];

    if (score === 0) {
        color0 = 'red';
    } else if (score === 1) {
        color0 = 'green';
        color1 = 'green';
    } else if (score === 2) {
        color0 = 'green';
        color1 = 'green';
        color2 = 'green';
    } else if (score === 3) {
        color0 = 'green';
        color1 = 'green';
        color2 = 'green';
        color3 = 'green';
    } else if (score > 3) {
        color0 = 'green';
        color1 = 'green';
        color2 = 'green';
        color3 = 'green';
        color4 = 'green';
    }

    return (
        <BoxContainer>
            <Box color={color0} />
            <Box color={color1} />
            <Box color={color2} />
            <Box color={color3} />
            <Box color={color4} />
        </BoxContainer>
    )
}

const BoxContainer = styled.div`
    display: flex;
    margin-top: 10px;
`;

const Box = styled.div`
    width: 30px;
    height: 5px;
    margin-right: 5px;

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export default PasswordStrengthIndicator
