export const white = "#ffffff";
export const gray100 = "#f0f0f0";
export const gray200 = "#e9ecef";
export const gray300 = "#dee2e6";
export const gray400 = "#ced4da";
export const gray500 = "#CED4DA";
export const gray600 = "#6c757d";
export const gray700 = "#131436";
export const gray800 = "#131436";
export const gray900 = "#131436";
export const black = "#000000";
export const blue = "#31B44E";
export const indigo = "#9b4ebf";
export const purple = "#9b4ebf";
export const pink = "#ff4081";
export const red = "#ce0000";
export const redBackground = "#eacece";
export const redStroke = "#B50000";
export const orange = "#FAA41A";
export const yellow = "#FAA41A";
export const yellowStroke = "#DE8D0B";
export const green = "#31B44E";
export const greenStroke = "#188E32";
export const teal = "#61C6BC";
export const tealStroke = "#42a59a";
export const cyan = "#61C6BC";
export const primary = "#31B44E";
export const primaryFont = "#ffffff";
export const secondary = "#131436";
export const secondaryFont = "#ffffff";
export const contentBg = "#ffffff";
export const contentPopupBg = "#ffffff";
export const success = "#31B44E";
export const info = "#17a2b8";
export const warning = "#FAA41A";
export const danger = "#ce0000";
export const light = "#f0f0f0";
export const dark = "#131436";
export const pageBg = "#ffffff";
export const pageFont = "#000000";